<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      Crecimiento mensual de la empresa
    </h5>
    <div class="card-body pb-0">
      <div class="row gy-4">
        <div class="col-xxl-3">
          <div class="mt-3 mb-3">
            <div class="row mb-4">
              <label class="col-sm-4 col-4 col-form-label text-truncate" title="Mes inicial">
                Mes inicial
              </label>
              <div class="col-sm-8 col-8">
                <Datepicker
                  ref="mesInicial"
                  v-model="mesInicial"
                  :class="'form-control bg-white'"
                  :minimumView="'month'"
                  :maximumView="'month'"
                  :inputFormat="'MMMM yyyy'"
                />
              </div>
            </div>
            <div class="row mb-4">
              <label class="col-sm-4 col-4 col-form-label text-truncate" title="Mes final">
                Mes final
              </label>
              <div class="col-sm-8 col-8">
                <Datepicker
                  ref="mesFinal"
                  v-model="mesFinal"
                  :class="'form-control bg-white'"
                  :minimumView="'month'"
                  :maximumView="'month'"
                  :inputFormat="'MMMM yyyy'"
                />
              </div>
            </div>
            
            <button class="btn btn-outline-success text-truncate w-100" @click="actualizar()">
              <i class="mdi mdi-refresh"></i>
              Refrescar
            </button>

            <br /><br /><br />

            <p class="text-muted mb-1 text-justify">
              Esta gráfica te ayuda a ver el crecimiento de tu empresa a lo
              largo del tiempo haciendo uso de unidades de crecimiento.
            </p>
          </div>
        </div>
        <div class="col-xxl-9">
          <div id="graficaCrecimiento">
            <apexchart
              class="apex-charts"
              height="350"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReporteSrv from '@/services/ReporteSrv.js'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'

export default {
  name: 'WCrecimiento',
  components: {
    Datepicker
  },

  data() {
    return {
      mesInicial: '01-2021',
      mesFinal: '12-2023',
      totales: [],

      // grafica
      series: [
        {
          name: "Unidades de Crecimiento",
          data: [],
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#63ad6f"],
        title: {
          text: "Unidades de Crecimiento",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [
            // "Ene",
            // "Feb",
            // "Mar",
            // "Apr",
            // "May",
            // "Jun",
            // "Jul",
            // "Aug",
            // "Sep",
            // "Oct",
            // "Nov",
            // "Dic"
          ],
          // title: {
          //   text: "Meses",
          // },
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      },
      // fin de la configuracion de la grafica
    }
  },

  mounted: function(){
    iu.spinner.mostrar('#graficaCrecimiento')
  },

  created() {
    var self = this

    let hoy = new Date()
    self.mesFinal = hoy
    var anio = hoy.getFullYear();
    var mes = hoy.getMonth();
    var dia = hoy.getDate();

    var menosAnios = 2
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
      menosAnios = 1
    }
    self.mesInicial = new Date(anio - menosAnios, mes, dia);
      
    self.actualizar()
    
    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    window.addEventListener('resize', () => {
      self.dibujarGrafica(self.totales)
    })
  },

  methods: {
    actualizar() {
      var self = this

      iu.spinner.mostrar('#graficaCrecimiento')
      var mesInicial = self.formatoFecha(self.mesInicial,'MM/YYYY')
      var mesFinal = self.formatoFecha(self.mesFinal,'MM/YYYY')
      
      ReporteSrv.ingresosTotalesPorMes(mesInicial,mesFinal,true).then(response => {
        self.totales = response.data
        self.dibujarGrafica(self.totales)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#graficaCrecimiento')
      })
    },

    dibujarGrafica(totales) {
      var self = this, cont = 0

      let categories = []
      self.series[0].data = []
      
      totales.forEach(t => {
        if(cont == 0){
          categories.push('')
          cont = 1
        } else {
          let fecha = self.formatoFecha(t.mes, 'MMM YY')
          categories.push(fecha)
          cont = 0
        }
        self.series[0].data.push(t.total)
      })

      self.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#63ad6f"],
        title: {
          text: "Unidades de Crecimiento",
          align: "left",
          style: {
            fontWeight: 400,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: categories,
          // title: {
          //   text: "Meses",
          // },
          tickAmount: 50,
          tooltip: {
            enabled: false,
          }
        },
      }
    },
    
    formatoFecha(mt, formato = 'MMMM YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },
  },
};
</script>

<style scoped>

</style>