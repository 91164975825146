<template>
  <Layout :tituloPagina="'Inicio ISP'">
    <div class="row">
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpTicketSoporte','componente')">
        <WSoportes></WSoportes>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpFacturas','componente')">
        <WFacturas></WFacturas>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpCpes','componente')">
        <WCpes></WCpes>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpClientes','componente')">
        <WClientes></WClientes>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12" v-if="tienePermiso('cmpCrecimientoMensual','componente')">
        <WCrecimiento></WCrecimiento>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6" v-if="tienePermiso('cmpNuevosClientes','componente')">
        <WClientesNuevosPorMes />
      </div>
      <div class="col-xxl-6" v-if="tienePermiso('cmpCancelacionesPorMes','componente')">
        <WCancelacion></WCancelacion>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-6">
        <!-- <router-link :to="{ name: 'soporteParaArgusBlack' }" class="btn btn-secondary text-truncate w-100" title="¿Tienes dudas o requieres soporte?">
          <i class="mdi mdi-help-circle-outline font-size-16 align-middle me-1"></i>
          Soporte para ArgusBlack
        </router-link> -->
        <a href="https://wa.me/5215527039900" class="btn btn-secondary text-truncate w-100" title="¿Tienes dudas o requieres soporte?" target="_blank">
          <i class="mdi mdi-help-circle-outline font-size-16 align-middle me-1"></i>
          Soporte para ArgusBlack
        </a>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"

import WSoportes from './WSoportes.vue'
import WFacturas from './WFacturas.vue'
import WCpes from './WCpes.vue'
import WClientes from './WClientes.vue'
import WCrecimiento from './WCrecimiento.vue'
import WClientesNuevosPorMes from './WClientesNuevosPorMes.vue'
import WCancelacion from './WCancelacion.vue'

import { todoGetters } from "@/state/helpers";

export default {
  name: 'Inicio',
  components: {
    Layout,
    WSoportes,
    WFacturas,
    WCpes,
    WClientes,
    WCrecimiento,
    WClientesNuevosPorMes,
    WCancelacion
  },
  computed: { ...todoGetters },
}
</script>